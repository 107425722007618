import {
  LOAD_DEPARTMENTS,
  LOAD_DEPARTMENTS_SUCCESSFUL,
  LOAD_DESIGNATIONS,
  LOAD_DESIGNATIONS_SUCCESSFUL,
  SUBMIT_EMPLOYEE,
  SUBMIT_EMPLOYEE_SUCCESSFUL,
  API_ERROR,
  SUBMIT_DESIGNATION,
  SUBMIT_DESIGNATION_SUCCESSFUL,
  SUBMIT_DEPARTMENT,
  SUBMIT_DEPARTMENT_SUCCESSFUL,
  SUBMIT_SHIFT,
  SUBMIT_SHIFT_SUCCESSFUL,
  LOAD_SHIFTS,
  LOAD_SHIFTS_SUCCESSFUL,
} from "./actionTypes";
export const loadDepartments = (departments) => {
  return {
    type: LOAD_DEPARTMENTS,
    payload: { departments },
  };
};

export const loadDepartmentsSuccessful = (departments) => {
  return {
    type: LOAD_DEPARTMENTS_SUCCESSFUL,
    payload: departments,
  };
};

export const loadDesignations = (designations) => {
  return {
    type: LOAD_DESIGNATIONS,
    payload: { designations },
  };
};

export const loadDesignationsSuccessful = (designations) => {
  return {
    type: LOAD_DESIGNATIONS_SUCCESSFUL,
    payload: designations,
  };
};

export const submitEmployee = (employee) => {
  return {
    type: SUBMIT_EMPLOYEE,
    payload: { employee },
  };
};

export const submitEmployeeSuccessful = (employee) => {
  return {
    type: SUBMIT_EMPLOYEE_SUCCESSFUL,
    payload: employee,
  };
};

export const submitDesignation = (designation) => {
  return {
    type: SUBMIT_DESIGNATION,
    payload: { designation },
  };
};

export const submitDesignationSuccessful = (designation) => {
  return {
    type: SUBMIT_DESIGNATION_SUCCESSFUL,
    payload: designation,
  };
};

export const submitDepartment = (department) => {
  return {
    type: SUBMIT_DEPARTMENT,
    payload: { department },
  };
};

export const submitDepartmentSuccessful = (department) => {
  return {
    type: SUBMIT_DEPARTMENT_SUCCESSFUL,
    payload: department,
  };
};

export const submitShift = (shift) => {
  return {
    type: SUBMIT_SHIFT,
    payload: { shift },
  };
};

export const submitShiftSuccessful = (shift) => {
  return {
    type: SUBMIT_SHIFT_SUCCESSFUL,
    payload: shift,
  };
};

export const loadShifts = (shifts) => {
  return {
    type: LOAD_SHIFTS,
    payload: { shifts },
  };
};

export const loadShiftsSuccessful = (shifts) => {
  return {
    type: LOAD_SHIFTS_SUCCESSFUL,
    payload: shifts,
  };
};

export const apiError = (data) => {
  return {
    type: API_ERROR,
    payload: data,
  };
};
