export const SUBMIT_ENQUIRY = "submit_enquiry";
export const SUBMIT_ENQUIRY_SUCCESSFUL = "submit_enquiry_successful";
export const SUBMIT_ENQUIRY_FAILED = "submit_enquiry_failed";
export const LOAD_PROGRAMS = "load_programs";
export const LOAD_PROGRAMS_SUCCESSFUL = "load_programs_successful";
export const API_ERROR = "api_error";
export const LOAD_ENQUIRIES = "load_enquiries";
export const LOAD_ENQUIRIES_SUCCESSFUL = "load_enquiries_successful";
export const UPDATE_ENQUIRY = "update_enquiry";
export const UPDATE_ENQUIRY_SUCCESSFUL = "update_enquiry_successful";
export const UPDATE_ENQUIRY_FAILED = "update_enquiry_failed";
export const LOAD_APPLICANTS = "load_applicants";
export const LOAD_APPLICANTS_SUCCESSFUL = "load_applicants_successful";
