import React, { Component, useState } from "react";
import makeAnimated from "react-select/animated";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

// action
import {
  loadDepartments,
  loadDesignations,
  submitEmployee,
} from "../../store/actions";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

const animatedComponents = makeAnimated();
class AddEmployees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_departments: [],
      department_designation: [],
      selected_department_id: null,
      selected_department_name: "",
      selected_designation_id: null,
      selected_designation_name: "",
    };
    // handleEnquirySubmit
    this.handleEmployeeSubmit = this.handleEmployeeSubmit.bind(this);
    this.showNotification.bind(this);
  }
  componentDidMount() {
    this.props.loadDepartments("");
    this.props.loadDesignations("");
  }

  handleChange = (event) => {
    if (event.target.name == "department") {
      const department_id = event.target.value;
      var index = event.nativeEvent.target.selectedIndex;
      const { options, value } = event.target;
      const department_name = options[index].label;
      this.setState({
        selected_department_id: department_id,
        selected_department_name: department_name,
      });
    } else if (event.target.name == "designation") {
      const designation_id = event.target.value;
      var index = event.nativeEvent.target.selectedIndex;
      const { options, value } = event.target;
      const designation_name = options[index].label;
      this.setState({
        selected_designation_id: designation_id,
        selected_designation_name: designation_name,
      });
    }
  };
  handleDepartmentDesignationSubmit = (event, values) => {
    const dept_desig = this.state.department_designation.find(
      (element) =>
        element.department_id === this.state.selected_department_id &&
        element.designation_id === this.state.selected_designation_id
    );
    if (
      !dept_desig &&
      this.state.selected_department_id &&
      this.state.selected_designation_id
    ) {
      const department_designation = this.state.department_designation;
      department_designation.push({
        department_id: this.state.selected_department_id,
        department_name: this.state.selected_department_name,
        designation_id: this.state.selected_designation_id,
        designation_name: this.state.selected_designation_name,
      });
      this.setState({ department_designation });
    }
  };

  showNotification(message, type) {
    var title = type;

    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 5000,
      extendedTimeOut: 1000,
      closeButton: true,
      progressBar: true,
      preventDuplicates: false,
      newestOnTop: true,
      showEasing: "linear",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
    };

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (type === "Success") toastr.success(message, title);
    else toastr.error(message, title);
  }

  // handleEmployeeSubmit
  handleEmployeeSubmit(event, values) {
    if (
      this.state.department_designation &&
      this.state.department_designation.length > 0
    ) {
      const data = {
        alternate_email: values.alternate_email,
        alternate_mobile: values.alternate_mobile,
        date_of_joining: values.date_of_joining,
        email: values.email,
        emp_code: values.emp_code,
        mobile: values.mobile,
        name: values.name,
        pwd: values.pwd,
        department_designation: this.state.department_designation,
      };
      this.props.submitEmployee(data);
      this.form.reset();
      this.setState({ department_designation: [] });
    } else {
      this.showNotification("Add at least one designation!", "Error");
    }
  }

  // Handle Department Select
  handleDepartmentChange = (selected, event) => {
    console.log(event);
    if (event.action === "select-option") {
      console.log("in select");
      const selected_department = {
        label: event.option.department + " - " + event.option.label,
        value: event.option.value,
        department: event.option.department,
      };
      // console.log(this.props.hs_subjects);
      let selected_departments = this.state.selected_departments;
      selected_departments.push(selected_department);
      console.log(selected_department);
      this.setState({
        selected_departments,
      });
    } else if (event.action === "remove-value") {
      console.log("in remove");

      const removed_department = {
        label: event.removedValue.department + " - " + event.removedValue.label,
        value: event.removedValue.value,
        department: event.removedValue.department,
      };
      // console.log(this.props.hs_subjects);
      let selected_departments = this.state.selected_departments;
      var index =
        selected_departments &&
        selected_departments.indexOf(removed_department);
      selected_departments.splice(index, 1);
      console.log(removed_department);
      this.setState({
        selected_departments,
      });
    }
    console.log(event);
  };
  render() {
    if (this.props.message) {
      this.showNotification(this.props.message, this.props.message_type);
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Dashboard" breadcrumbItem="Add Employees" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      id="employee_form"
                      ref={(form) => (this.form = form)}
                      className="needs-validation"
                      onValidSubmit={this.handleEmployeeSubmit}
                    >
                      <Row>
                        <Col lg="12">
                          <CardTitle>Employee Details:</CardTitle>
                          <hr />
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label htmlFor="validationCustom01">
                              Employee Code
                            </Label>
                            <AvField
                              name="emp_code"
                              placeholder="Enter Employee Code"
                              type="text"
                              errorMessage="Enter Employee Code"
                              className="form-control"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label htmlFor="validationCustom01">Password</Label>
                            <AvField
                              name="pwd"
                              placeholder="Set Password"
                              type="text"
                              errorMessage="Enter Password"
                              className="form-control"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label htmlFor="validationCustom01">Name</Label>
                            <AvField
                              name="name"
                              placeholder="Enter Name"
                              type="text"
                              errorMessage="Enter Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label htmlFor="validationCustom02">
                              Mobile Number
                            </Label>
                            <AvField
                              name="mobile"
                              placeholder="Enter Mobile Number"
                              type="text"
                              errorMessage="Enter Mobile Number"
                              className="form-control"
                              validate={{
                                required: { value: true },
                                maxLength: {
                                  value: 10,
                                  errorMessage: "Invalid mobile.",
                                },
                                minLength: {
                                  value: 10,
                                  errorMessage: "Invalid mobile.",
                                },
                              }}
                              id="validationCustom02"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label>Alternate Mobile Number</Label>
                            <AvField
                              name="alternate_mobile"
                              placeholder="Enter Alternate Mobile"
                              type="email"
                              errorMessage="Enter Valid Mobile"
                              className="form-control"
                              validate={{
                                maxLength: {
                                  value: 10,
                                  errorMessage: "Invalid mobile.",
                                },
                                minLength: {
                                  value: 10,
                                  errorMessage: "Invalid mobile.",
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label>Email ID</Label>
                            <AvField
                              name="email"
                              placeholder="Enter Email ID"
                              type="email"
                              errorMessage="Enter Email ID"
                              className="form-control"
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label>Alternate Email ID</Label>
                            <AvField
                              name="alternate_email"
                              placeholder="Enter Alternate Email ID"
                              type="email"
                              errorMessage="Enter Valid Email ID"
                              className="form-control"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label>Date of Joining</Label>
                            <AvField
                              name="date_of_joining"
                              placeholder="Select Date"
                              type="date"
                              errorMessage="Select Date"
                              className="form-control"
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col md="8">
                          <FormGroup className="mb-0 templating-select select2-container">
                            <Label>Department and Designations</Label>
                            <Select
                              value={this.state.selected_departments}
                              isMulti={true}
                              name="department_designation"
                              onChange={this.handleDepartmentChange}
                              options={this.props.departments}
                              getOptionValue={(option) => option["label"]}
                              classNamePrefix="select2-selection"
                              components={animatedComponents}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <CardTitle>Departments and Designations:</CardTitle>
                      <hr />
                      {/* <AvForm
                        onValidSubmit={this.handleDepartmentDesignationSubmit}
                      > */}
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <Label htmlFor="validationCustom01">
                              Department
                            </Label>
                            <AvField
                              name="department"
                              type="select"
                              errorMessage="Select Department"
                              className="form-control"
                              onChange={this.handleChange}
                            >
                              <option value="" label="--Select Department--" />
                              {this.props.departments ? (
                                this.props.departments.map((department) => (
                                  <option
                                    value={department._id}
                                    label={department.name}
                                  />
                                ))
                              ) : (
                                <option>--No Departments--</option>
                              )}
                            </AvField>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label htmlFor="validationCustom01">
                              Designation
                            </Label>
                            <AvField
                              name="designation"
                              type="select"
                              errorMessage="Select Designation"
                              onChange={this.handleChange}
                              className="form-control"
                            >
                              <option value="" label="--Select Designation--" />
                              {this.props.designations ? (
                                this.props.designations.map((designation) => (
                                  <option
                                    value={designation._id}
                                    label={designation.name}
                                  />
                                ))
                              ) : (
                                <option>--No Designations--</option>
                              )}
                            </AvField>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <Label htmlFor="validationCustom01">
                            Added Designations
                          </Label>
                          <BootstrapTable
                            data={
                              this.state.department_designation &&
                              this.state.department_designation
                            }
                            striped
                            responsive
                            hover
                          >
                            <TableHeaderColumn
                              autoValue={true}
                              dataField="_id"
                              isKey={true}
                              hidden={true}
                            >
                              Id
                            </TableHeaderColumn>

                            <TableHeaderColumn
                              dataField="designation_name"
                              width="120"
                              tdStyle={{ whiteSpace: "normal" }}
                            >
                              Designation
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="department_name"
                              width="150"
                              tdStyle={{ whiteSpace: "normal" }}
                            >
                              Department
                            </TableHeaderColumn>
                          </BootstrapTable>
                        </Col>
                        <Col lg="2">
                          <Link
                            className="btn btn-primary btn-block waves-effect waves-light"
                            onClick={this.handleDepartmentDesignationSubmit}
                          >
                            Add Designation
                          </Link>
                        </Col>
                      </Row>
                      {/* </AvForm> */}
                      <hr />
                      <Button color="primary" type="submit">
                        Submit
                      </Button>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    departments,
    message,
    message_type,
    loading,
    designations,
  } = state.Administration;
  return {
    loading,
    departments,
    designations,
    message,
    message_type,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    loadDepartments,
    loadDesignations,
    submitEmployee,
  })(AddEmployees)
);
