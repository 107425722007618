import {
  SUBMIT_ENQUIRY,
  SUBMIT_ENQUIRY_SUCCESSFUL,
  SUBMIT_ENQUIRY_FAILED,
  LOAD_PROGRAMS,
  LOAD_PROGRAMS_SUCCESSFUL,
  LOAD_ENQUIRIES,
  LOAD_ENQUIRIES_SUCCESSFUL,
  API_ERROR,
  UPDATE_ENQUIRY,
  UPDATE_ENQUIRY_SUCCESSFUL,
  UPDATE_ENQUIRY_FAILED,
  LOAD_APPLICANTS,
  LOAD_APPLICANTS_SUCCESSFUL,
} from "./actionTypes";

export const submitEnquiry = (enquiryDetails) => {
  return {
    type: SUBMIT_ENQUIRY,
    payload: { enquiryDetails },
  };
};

export const submitEnquirySuccessful = (enquiryDetails) => {
  return {
    type: SUBMIT_ENQUIRY_SUCCESSFUL,
    payload: enquiryDetails,
  };
};

export const submitEnquiryFailed = (enquiryDetails) => {
  return {
    type: SUBMIT_ENQUIRY_FAILED,
    payload: enquiryDetails,
  };
};

export const loadPrograms = (programs) => {
  return {
    type: LOAD_PROGRAMS,
    payload: { programs },
  };
};

export const loadProgramsSuccessful = (programs) => {
  return {
    type: LOAD_PROGRAMS_SUCCESSFUL,
    payload: programs,
  };
};

export const apiError = (data) => {
  return {
    type: API_ERROR,
    payload: data,
  };
};

export const loadEnquiries = (enquiries) => {
  return {
    type: LOAD_ENQUIRIES,
    payload: { enquiries },
  };
};

export const loadEnquiriesSuccessful = (enquiries) => {
  return {
    type: LOAD_ENQUIRIES_SUCCESSFUL,
    payload: enquiries,
  };
};

export const updateEnquiry = (enquiryDetails) => {
  return {
    type: UPDATE_ENQUIRY,
    payload: { enquiryDetails },
  };
};

export const updateEnquirySuccessful = (enquiryDetails) => {
  return {
    type: UPDATE_ENQUIRY_SUCCESSFUL,
    payload: enquiryDetails,
  };
};

export const updateEnquiryFailed = (enquiryDetails) => {
  return {
    type: UPDATE_ENQUIRY_FAILED,
    payload: enquiryDetails,
  };
};

export const loadApplicants = (applicants) => {
  return {
    type: LOAD_APPLICANTS,
    payload: { applicants },
  };
};

export const loadApplicantsSuccessful = (applicants) => {
  return {
    type: LOAD_APPLICANTS_SUCCESSFUL,
    payload: { applicants },
  };
};
