import React, { Component, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// action
import {
  submitEnquiry,
  updateEnquiry,
  loadPrograms,
  loadEnquiries,
} from "../../store/actions";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  MDBDataTable,
  MDBDataTableV5,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdbreact";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

class Enquiries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      specificSource: [],
      isSubmitted: false,
    };
    // handleEnquirySubmit
    this.handleEnquirySubmit = this.handleEnquirySubmit.bind(this);
    // handleEnquiryUpdate
    this.handleEnquiryUpdate = this.handleEnquiryUpdate.bind(this);
    this.showNotification.bind(this);
  }
  componentDidMount() {
    this.props.loadPrograms("");
    this.props.loadEnquiries("");
  }

  showNotification() {
    console.log(this.props.message);
    if (this.props.message && this.state.isSubmitted) {
      this.setState({ isSubmitted: false });
      if (
        this.props.message === "Enquiry submitted successfully." ||
        this.props.message === "Enquiry updated successfully."
      )
        var title = "Success";
      else title = "Error";
      var message = this.props.message;

      toastr.options = {
        positionClass: "toast-top-right",
        timeOut: 5000,
        extendedTimeOut: 1000,
        closeButton: true,
        progressBar: true,
        preventDuplicates: false,
        newestOnTop: true,
        showEasing: "linear",
        hideEasing: "linear",
        showMethod: "fadeIn",
        hideMethod: "fadeOut",
        showDuration: 300,
        hideDuration: 1000,
      };

      // setTimeout(() => toastr.success(`Settings updated `), 300)
      //Toaster Types
      if (
        this.props.message === "Enquiry submitted successfully." ||
        this.props.message === "Enquiry updated successfully."
      )
        toastr.success(message, title);
      else toastr.error(message, title);
    }
  }
  checkboxChecked = (e) => {
    //console.log(e);
    //this.props.history.push("/dashboard");
    this.setState({ selectedEnquiry: e }, function () {
      this.setState({ isModal: !this.state.modal });
    });
  };

  // handleRegisterSubmit
  handleEnquirySubmit(event, values) {
    const program_name = this.props.programs.find(
      (program) => program._id === values.program_id
    );
    if (program_name) {
      const data = {
        name: values.name,
        mobile: values.mobile,
        email: values.email,
        program_id: values.program_id,
        city: values.city,
        state: values.state,
        reference: { ref_type: values.ref_type, ref_name: values.ref_name },
        call_details: {
          call_type: values.call_type,
          remarks: values.remarks,
          next_call_date: values.next_call_date,
        },
        program_name: program_name.name,
        source: "erp",
        query: "",
      };
      this.props.submitEnquiry(data);
      this.form.reset();
      this.setState({ isSubmitted: true });
    }
  }
  handleEnquiryUpdate(event, values) {
    const data = {
      ticket: this.state.selectedEnquiry.ticket,
      call_details: {
        call_type: values.update_call_type,
        remarks: values.update_remarks,
        next_call_date: values.update_next_call_date,
      },
    };
    console.log(data);
    this.props.updateEnquiry(data);
    //this.form.reset();
    this.setState({ isSubmitted: true, isModal: false });
  }
  getSpecificSource = (event) => {
    if (event.target.value === "Friends or Relatives") {
      this.setState({ specificSource: ["Friends", "Relatives"] });
    } else if (event.target.value === "Internet") {
      this.setState({
        specificSource: ["Google", "Facebook", "Institute Website"],
      });
    } else if (event.target.value === "Newspaper") {
      this.setState({
        specificSource: [
          "The Hindu",
          "Telegraph",
          "Times of India",
          "Hindustan Times",
          "Dainik Jagran",
          "Amar Ujala",
        ],
      });
    } else if (event.target.value === "Radio") {
      this.setState({
        specificSource: ["Red FM", "Radio City", "Radio Mirchi"],
      });
    }
  };
  onRowSelect = (row, isSelected, e) => {
    // let rowStr = "";
    // for (const prop in row) {
    //   rowStr += prop + ': "' + row[prop] + '"';
    // }
    this.setState({ selectedEnquiry: row }, function () {
      this.setState({ isModal: !this.state.modal });
    });
  };
  render() {
    if (this.props.message) {
      this.showNotification();
    }
    const data = {
      columns: [
        {
          label: "Ticket ID",
          field: "ticket",
          sort: "disabled",
          width: 80,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Ticket ID",
          },
        },

        {
          label: "Name",
          field: "name",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Mobile",
          field: "mobile",
          sort: "disabled",
          width: 70,
        },
        {
          label: "Email",
          field: "email",
          sort: "disabled",
          width: 200,
        },
        {
          label: "Program of Interest",
          field: "program_name",
          sort: "disabled",
          width: 270,
        },
        {
          label: "Next Call Date",
          field: "latest_next_call_date_string",
          sort: "disabled",
          width: 200,
        },
      ],
      rows: this.props.enquiries,
    };

    const showLogs2 = (e) => {
      this.checkboxChecked(e);
    };

    const data_calldetails = {
      columns: [
        {
          label: "Call Date",
          field: "call_date",
          sort: "asc",
          width: 100,
        },

        {
          label: "Call Type",
          field: "call_type",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Remarks",
          field: "remarks",
          sort: "disabled",
          width: 70,
        },
        {
          label: "Next Call Date",
          field: "next_call_date",
          sort: "disabled",
          width: 200,
        },
      ],
      rows:
        this.state.selectedEnquiry && this.state.selectedEnquiry.call_details,
    };

    const selectRowProp = {
      mode: "radio",
      clickToSelect: true,
      onSelect: this.onRowSelect,
      columnWidth: "30px",
    };
    const pagination_options = {
      page: 1, // which page you want to show as default
      sizePerPageList: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "20",
          value: 20,
        },
        {
          text: "All",
          value: this.props.enquiries && this.props.enquiries.length,
        },
      ], // you can change the dropdown list for size per page
      sizePerPage: 5, // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 2, // the pagination bar size.
      prePage: "Prev", // Previous page button text
      nextPage: "Next", // Next page button text
      firstPage: "First", // First page button text
      lastPage: "Last", // Last page button text
      paginationShowsTotal: this.renderShowsTotal, // Accept bool or function
      paginationPosition: "bottom", // default is bottom, top and both is all available
      hideSizePerPage: true, //> You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs
              title="Dashboard"
              breadcrumbItem="Admission Enquiries"
            />
            {sessionStorage.getItem("role") === "operator" && (
              <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      {/* <h4 className="card-title">React Validation - Normal</h4>
                    <p className="card-title-desc">
                      Provide valuable, actionable feedback to your users with
                      HTML5 form validation–available in all our supported
                      browsers.
                    </p> */}
                      <AvForm
                        ref={(form) => (this.form = form)}
                        className="needs-validation"
                        onValidSubmit={this.handleEnquirySubmit}
                      >
                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <label for="call_type" class="">
                                Call Type{" "}
                              </label>
                              <AvRadioGroup inline name="call_type" required>
                                <AvRadio label="Incoming" value="Incoming" />
                                <AvRadio label="Outgoing" value="Outgoing" />
                              </AvRadioGroup>
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="validationCustom01">Name</Label>
                              <AvField
                                name="name"
                                placeholder="Enter Name"
                                type="text"
                                errorMessage="Enter Name"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="validationCustom01"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="validationCustom02">
                                Mobile Number
                              </Label>
                              <AvField
                                name="mobile"
                                placeholder="Enter Mobile Number"
                                type="text"
                                errorMessage="Enter Mobile Number"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                  maxLength: {
                                    value: 10,
                                    errorMessage: "Invalid mobile.",
                                  },
                                  minLength: {
                                    value: 10,
                                    errorMessage: "Invalid mobile.",
                                  },
                                }}
                                id="validationCustom02"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <Label>Email ID</Label>
                              <AvField
                                name="email"
                                placeholder="Enter Email ID"
                                type="email"
                                errorMessage="Enter Email ID"
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label>Source of Information</Label>
                              <div className="form-group">
                                <AvField
                                  name="ref_type"
                                  type="select"
                                  onChange={this.getSpecificSource}
                                >
                                  <option value="" label="--Select Source--" />
                                  <option
                                    value="Friends or Relatives"
                                    label="Friends or Relatives"
                                  />
                                  <option value="Internet" label="Internet" />
                                  <option value="Newspaper" label="Newspaper" />
                                  <option value="Radio" label="Radio" />
                                </AvField>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label>Specific Source</Label>
                              <div className="form-group">
                                <AvField name="ref_name" type="select">
                                  <option
                                    value=""
                                    label="--Select Specific Source--"
                                  />
                                  {this.state.specificSource ? (
                                    this.state.specificSource.map((source) => (
                                      <option value={source} label={source} />
                                    ))
                                  ) : (
                                    <option>--No Specific Source--</option>
                                  )}
                                </AvField>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="validationCustom03">
                                Program Interested In
                              </Label>
                              <div className="form-group">
                                <AvField
                                  name="program_id"
                                  type="select"
                                  required
                                >
                                  <option value="" label="--Select Program--" />
                                  {this.props.programs ? (
                                    this.props.programs.map((program) => (
                                      <option
                                        value={program._id}
                                        label={program.name}
                                      />
                                    ))
                                  ) : (
                                    <option>--No Programs--</option>
                                  )}
                                </AvField>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="validationCustom05">City</Label>
                              <AvField
                                name="city"
                                placeholder="Enter City Name"
                                type="text"
                                errorMessage=" Please provide a valid city."
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="validationCustom04">State</Label>
                              <AvField
                                name="state"
                                placeholder="Enter State Name"
                                type="text"
                                errorMessage="Please provide a valid state."
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <hr />
                        <Row>
                          <Col md="8">
                            <FormGroup>
                              <Label>Remarks</Label>
                              <AvField
                                name="remarks"
                                placeholder="Enter Remarks"
                                type="textarea"
                                errorMessage="Enter Remarks"
                                className="form-control"
                                validate={{ required: { value: true } }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label>Next Call Date</Label>
                              <AvField
                                name="next_call_date"
                                placeholder="Select Next Call Date"
                                type="datetime-local"
                                errorMessage="Select Next Call Date"
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <hr />
                        <Button color="primary" type="submit">
                          Submit
                        </Button>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Modal
                      size="lg"
                      isOpen={this.state.isModal}
                      toggle={() =>
                        this.setState({ isModal: !this.state.isModal })
                      }
                    >
                      <ModalHeader
                        toggle={() =>
                          this.setState({ isModal: !this.state.isModal })
                        }
                      >
                        Ticket ID:{" "}
                        {this.state.selectedEnquiry &&
                          this.state.selectedEnquiry.ticket}
                      </ModalHeader>
                      <ModalBody>
                        {this.state.selectedEnquiry && (
                          <MDBDataTableV5
                            hover
                            entriesOptions={[3, 5, 10, 20]}
                            entries={3}
                            pagesAmount={4}
                            data={data_calldetails}
                            responsive
                          />
                        )}
                        {sessionStorage.getItem("role") === "operator" && (
                          <>
                            <hr />
                            <CardTitle>Add New Call Details</CardTitle>
                            <hr />
                            <AvForm
                              ref={(form) => (this.form = form)}
                              className="needs-validation"
                              onValidSubmit={this.handleEnquiryUpdate}
                            >
                              <Row>
                                <Col md="4">
                                  <FormGroup>
                                    <label for="update_call_type" class="">
                                      Call Type{" "}
                                    </label>
                                    <AvRadioGroup
                                      inline
                                      name="update_call_type"
                                      required
                                    >
                                      <AvRadio
                                        label="Incoming"
                                        value="Incoming"
                                      />
                                      <AvRadio
                                        label="Outgoing"
                                        value="Outgoing"
                                      />
                                    </AvRadioGroup>
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Row>
                                <Col md="8">
                                  <FormGroup>
                                    <Label>Remarks</Label>
                                    <AvField
                                      name="update_remarks"
                                      placeholder="Enter Remarks"
                                      type="textarea"
                                      errorMessage="Enter Remarks"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup>
                                    <Label>Next Call Date</Label>
                                    <AvField
                                      name="update_next_call_date"
                                      placeholder="Select Next Call Date"
                                      type="datetime-local"
                                      errorMessage="Select Next Call Date"
                                      className="form-control"
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <hr />
                              <Button color="primary" type="submit">
                                Submit
                              </Button>
                            </AvForm>
                          </>
                        )}
                      </ModalBody>
                    </Modal>
                    <CardTitle>Open Enquiries </CardTitle>
                    <CardSubtitle className="mb-3">
                      View and edit all your open enquiries here.
                    </CardSubtitle>
                    {data.rows && (
                      // <MDBDataTableV5
                      //   hover
                      //   entriesOptions={[10, 20, 50]}
                      //   entries={10}
                      //   pagesAmount={4}
                      //   data={data}
                      //   checkbox
                      //   responsive
                      //   autoWidth
                      //   headCheckboxID="id2"
                      //   bodyCheckboxID="checkboxes2"
                      //   getValueCheckBox={(e) => {
                      //     showLogs2(e);
                      //   }}
                      // />
                      <BootstrapTable
                        data={this.props.enquiries && this.props.enquiries}
                        selectRow={selectRowProp}
                        pagination={true}
                        options={pagination_options}
                        striped
                        search
                        exportCSV
                        ignoreSinglePage
                        responsive
                        hover
                      >
                        <TableHeaderColumn
                          dataField="ticket"
                          isKey={true}
                          width="70"
                          tdStyle={{ whiteSpace: "normal" }}
                        >
                          Ticket
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="name"
                          width="70"
                          tdStyle={{ whiteSpace: "normal" }}
                        >
                          Name
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="mobile"
                          width="70"
                          tdStyle={{ whiteSpace: "normal" }}
                        >
                          Mobile
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="email"
                          width="135"
                          tdStyle={{ whiteSpace: "normal" }}
                        >
                          Email
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="program_name"
                          width="140"
                          tdStyle={{ whiteSpace: "normal" }}
                        >
                          Program of Interest
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="latest_next_call_date_string"
                          width="100"
                          tdStyle={{ whiteSpace: "normal" }}
                        >
                          Next Call Date
                        </TableHeaderColumn>
                      </BootstrapTable>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { programs, message, loading, enquiries } = state.Admission;
  return {
    loading,
    programs,
    message,
    enquiries,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    submitEnquiry,
    updateEnquiry,
    loadPrograms,
    loadEnquiries,
  })(Enquiries)
);
