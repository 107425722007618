import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { loginSuccess, logoutUserSuccess, apiError } from "./actions";

import { postLogin } from "../../../helpers/auth_helper";

function* loginUser({ payload: { user, history } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "employees/loginwithpassword";

    const response = yield call(postLogin, url, user);

    if (response.status === 1) {
      sessionStorage.setItem("authUser", response.data.emp_code);
      sessionStorage.setItem("emp_code", response.data.emp_code);
      sessionStorage.setItem("name", response.data.name);
      sessionStorage.setItem("mobile", response.data.mobile);
      sessionStorage.setItem("email", response.data.email);
      sessionStorage.setItem("role", response.data.role);
      sessionStorage.setItem("userToken", response.data.token);
      yield put(loginSuccess(response));
      history.push("/dashboard");
    } else {
      yield put(apiError(response.message));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    sessionStorage.removeItem("authUser");

    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //   const response = yield call(fireBaseBackend.logout);
    //   yield put(logoutUserSuccess(response));
    // }
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
