import React, { Component, useState } from "react";
import makeAnimated from "react-select/animated";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

// action
import { loadShifts, submitShift } from "../../store/actions";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

class Shifts extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // handleEnquirySubmit
    this.handleShiftSubmit = this.handleShiftSubmit.bind(this);
    this.showNotification.bind(this);
  }
  componentDidMount() {
    this.props.loadShifts("");
  }

  showNotification(message, type) {
    var title = type;

    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 5000,
      extendedTimeOut: 1000,
      closeButton: true,
      progressBar: true,
      preventDuplicates: false,
      newestOnTop: true,
      showEasing: "linear",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
    };

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (type === "Success") toastr.success(message, title);
    else toastr.error(message, title);
  }

  // handleEmployeeSubmit
  handleShiftSubmit(event, values) {
    this.props.submitShift(values);
    this.form.reset();
  }

  render() {
    if (this.props.message) {
      this.showNotification(this.props.message, this.props.message_type);
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Dashboard" breadcrumbItem="Shifts" />
            <Row>
              <Col xl="6">
                <Card>
                  <CardBody>
                    <CardTitle>Add Shifts </CardTitle>
                    <CardSubtitle className="mb-3">
                      View and add new shifts here.
                    </CardSubtitle>
                    <AvForm
                      id="designations_form"
                      ref={(form) => (this.form = form)}
                      className="needs-validation"
                      onValidSubmit={this.handleShiftSubmit}
                    >
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <Label htmlFor="validationCustom01">
                              Name of Shift
                            </Label>
                            <AvField
                              name="name"
                              placeholder="Enter Shift Name"
                              type="text"
                              errorMessage="Enter Shift Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label htmlFor="validationCustom01">
                              Start Time
                            </Label>
                            <AvField
                              name="start_time"
                              placeholder="Enter Start Time"
                              type="time"
                              errorMessage="Enter Start Time"
                              className="form-control"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label htmlFor="validationCustom01">End Time</Label>
                            <AvField
                              name="end_time"
                              placeholder="Enter End Time"
                              type="time"
                              errorMessage="Enter End Time"
                              className="form-control"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label for="shift_type" class="">
                              Shift Type{" "}
                            </label>
                            <AvRadioGroup inline name="shift_type" required>
                              <AvRadio label="Same Day" value="same_day" />
                              <AvRadio label="Next Day" value="next_day" />
                            </AvRadioGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Button color="primary" type="submit">
                        Submit
                      </Button>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle>Existing Shifts </CardTitle>
                    <BootstrapTable
                      data={this.props.shifts && this.props.shifts}
                      striped
                      search
                      exportCSV
                      responsive
                      hover
                    >
                      <TableHeaderColumn
                        autoValue={true}
                        dataField="_id"
                        isKey={true}
                        hidden={true}
                      >
                        Id
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="name"
                        width="250"
                        tdStyle={{ whiteSpace: "normal" }}
                      >
                        Shift Name
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="start_time"
                        width="70"
                        tdStyle={{ whiteSpace: "normal" }}
                      >
                        Start Time
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="end_time"
                        width="70"
                        tdStyle={{ whiteSpace: "normal" }}
                      >
                        End Time
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="shift_type"
                        width="100"
                        tdStyle={{ whiteSpace: "normal" }}
                      >
                        Shift Type
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { message, message_type, loading, shifts } = state.Administration;
  return {
    loading,
    shifts,
    message,
    message_type,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    loadShifts,
    submitShift,
  })(Shifts)
);
