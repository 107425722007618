import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Enquiry Redux states
import {
  LOAD_DEPARTMENTS,
  LOAD_DESIGNATIONS,
  SUBMIT_EMPLOYEE,
  SUBMIT_DESIGNATION,
  SUBMIT_DEPARTMENT,
  SUBMIT_SHIFT,
  LOAD_SHIFTS,
} from "./actionTypes";
import {
  loadDepartmentsSuccessful,
  loadDesignationsSuccessful,
  submitEmployeeSuccessful,
  submitDesignationSuccessful,
  submitDepartmentSuccessful,
  submitShiftSuccessful,
  loadShiftsSuccessful,
  apiError,
} from "./actions";
//Include Helper Files with needed methods
import { postSubmitForm } from "../../helpers/forms_helper";

//Load Departments
function* loadDepartments({ payload: { departments } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "departments/getall";
    const response = yield call(postSubmitForm, url, departments);

    if (response.status === 1) {
      yield put(loadDepartmentsSuccessful(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchLoadDepartments() {
  yield takeEvery(LOAD_DEPARTMENTS, loadDepartments);
}

//Load Designations
function* loadDesignations({ payload: { designations } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "designations/getall";
    const response = yield call(postSubmitForm, url, designations);

    if (response.status === 1) {
      yield put(loadDesignationsSuccessful(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchLoadDesignations() {
  yield takeEvery(LOAD_DESIGNATIONS, loadDesignations);
}

//Submit Employee
function* submitEmployee({ payload: { employee } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "employees/insert";
    const response = yield call(postSubmitForm, url, employee);

    if (response.status === 1) {
      yield put(submitEmployeeSuccessful(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchSubmitEmployee() {
  yield takeEvery(SUBMIT_EMPLOYEE, submitEmployee);
}

//Submit Designation
function* submitDesignation({ payload: { designation } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "designations/insert";
    const response = yield call(postSubmitForm, url, designation);

    if (response.status === 1) {
      yield* bindDesignations();
      yield put(submitDesignationSuccessful(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchSubmitDesignation() {
  yield takeEvery(SUBMIT_DESIGNATION, submitDesignation);
}

//Submit Department
function* submitDepartment({ payload: { department } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "departments/insert";
    const response = yield call(postSubmitForm, url, department);

    if (response.status === 1) {
      yield* bindDepartments();
      yield put(submitDepartmentSuccessful(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchSubmitDepartment() {
  yield takeEvery(SUBMIT_DEPARTMENT, submitDepartment);
}

//Submit Shift
function* submitShift({ payload: { shift } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "shifts/insert";
    const response = yield call(postSubmitForm, url, shift);

    if (response.status === 1) {
      yield* bindShifts();
      yield put(submitShiftSuccessful(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchSubmitShift() {
  yield takeEvery(SUBMIT_SHIFT, submitShift);
}

//Load Shifts
function* loadShifts({ payload: { shifts } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "shifts/getall";
    const response = yield call(postSubmitForm, url, shifts);

    if (response.status === 1) {
      yield put(loadShiftsSuccessful(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchLoadShifts() {
  yield takeEvery(LOAD_SHIFTS, loadShifts);
}

//Bind Shifts
function* bindShifts() {
  try {
    let url = process.env.REACT_APP_BASEURL + "shifts/getall";
    const response = yield call(postSubmitForm, url, "");
    if (response.status === 1) {
      yield put(loadShiftsSuccessful(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

//Bind Designations
function* bindDesignations() {
  try {
    let url = process.env.REACT_APP_BASEURL + "designations/getall";
    const response = yield call(postSubmitForm, url, "");
    if (response.status === 1) {
      yield put(loadDesignationsSuccessful(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

//Bind Departments
function* bindDepartments() {
  try {
    let url = process.env.REACT_APP_BASEURL + "departments/getall";
    const response = yield call(postSubmitForm, url, "");
    if (response.status === 1) {
      yield put(loadDepartmentsSuccessful(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* administrationSaga() {
  yield all([
    fork(watchLoadDepartments),
    fork(watchLoadDesignations),
    fork(watchSubmitEmployee),
    fork(watchSubmitDesignation),
    fork(watchSubmitDepartment),
    fork(watchSubmitShift),
    fork(watchLoadShifts),
  ]);
}

export default administrationSaga;
