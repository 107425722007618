import React, { Component, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// action
import { loadApplicants } from "../../store/actions";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

class Applicants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedApplicant: {},
    };
  }
  componentDidMount() {
    this.props.loadApplicants("");
  }
  onRowSelect = (row, isSelected, e) => {
    // let rowStr = "";
    // for (const prop in row) {
    //   rowStr += prop + ': "' + row[prop] + '"';
    // }
    this.setState({ selectedApplicant: row }, function () {
      this.setState({ isModal: !this.state.modal });
    });
  };
  render() {
    const selectRowProp = {
      mode: "radio",
      clickToSelect: true,
      onSelect: this.onRowSelect,
      columnWidth: "30px",
    };
    const pagination_options = {
      page: 1, // which page you want to show as default
      sizePerPageList: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "20",
          value: 20,
        },
        {
          text: "All",
          value: this.props.applicants && this.props.applicants.length,
        },
      ], // you can change the dropdown list for size per page
      sizePerPage: 5, // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 2, // the pagination bar size.
      prePage: "Prev", // Previous page button text
      nextPage: "Next", // Next page button text
      firstPage: "First", // First page button text
      lastPage: "Last", // Last page button text
      paginationShowsTotal: this.renderShowsTotal, // Accept bool or function
      paginationPosition: "bottom", // default is bottom, top and both is all available
      hideSizePerPage: true, //> You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs
              title="Dashboard"
              breadcrumbItem="Admission Applicants"
            />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Modal
                      size="lg"
                      isOpen={this.state.isModal}
                      toggle={() =>
                        this.setState({ isModal: !this.state.isModal })
                      }
                    >
                      <ModalHeader
                        toggle={() =>
                          this.setState({ isModal: !this.state.isModal })
                        }
                      >
                        Applicant Details:{" "}
                        {this.state.selectedApplicant &&
                          this.state.selectedApplicant.ticket}
                      </ModalHeader>
                      <ModalBody>
                        <Row>
                          <Col xl="12">
                            <Card>
                              <CardBody>
                                <dl className="row mb-0">
                                  <dt className="col-sm-3">Name</dt>
                                  <dd className="col-sm-3">
                                    {this.state.selectedApplicant.name}
                                  </dd>
                                  <dt className="col-sm-3">Gender</dt>
                                  <dd className="col-sm-3">
                                    {this.state.selectedApplicant.gender}
                                  </dd>
                                  <dt className="col-sm-3">Date of Birth</dt>
                                  <dd className="col-sm-3">
                                    {this.state.selectedApplicant
                                      .date_of_birth &&
                                      new Intl.DateTimeFormat("en-GB", {
                                        year: "numeric",
                                        month: "long",
                                        day: "2-digit",
                                      }).format(
                                        new Date(
                                          this.state.selectedApplicant.date_of_birth
                                        )
                                      )}
                                  </dd>
                                  <dt className="col-sm-3">Nationality</dt>
                                  <dd className="col-sm-3">
                                    {this.state.selectedApplicant.nationality}
                                  </dd>
                                  <dt className="col-sm-3">Religion</dt>
                                  <dd className="col-sm-3">
                                    {this.state.selectedApplicant.religion}
                                  </dd>
                                  <dt className="col-sm-3">Category</dt>
                                  <dd className="col-sm-3">
                                    {this.state.selectedApplicant.category}
                                  </dd>
                                  <dt className="col-sm-3">Father's Name</dt>
                                  <dd className="col-sm-3">
                                    {this.state.selectedApplicant.father_name}
                                  </dd>
                                  <dt className="col-sm-3">Mother's Name</dt>
                                  <dd className="col-sm-3">
                                    {this.state.selectedApplicant.mother_name}
                                  </dd>
                                  <hr />
                                  <dt className="col-sm-3">
                                    Correspondence Address:
                                  </dt>
                                  <dd className="col-sm-9 mb-0">
                                    <dl className="row mb-0">
                                      <dt className="col-sm-4">Address</dt>
                                      <dd className="col-sm-8">
                                        {this.state.selectedApplicant
                                          .correspondence_address &&
                                          this.state.selectedApplicant
                                            .correspondence_address.address}
                                      </dd>
                                      <dt className="col-sm-4">City</dt>
                                      <dd className="col-sm-8">
                                        {this.state.selectedApplicant
                                          .correspondence_address &&
                                          this.state.selectedApplicant
                                            .correspondence_address.city}
                                      </dd>
                                      <dt className="col-sm-4">State</dt>
                                      <dd className="col-sm-8">
                                        {this.state.selectedApplicant
                                          .correspondence_address &&
                                          this.state.selectedApplicant
                                            .correspondence_address.state}
                                      </dd>
                                      <dt className="col-sm-4">Pincode</dt>
                                      <dd className="col-sm-8">
                                        {this.state.selectedApplicant
                                          .correspondence_address &&
                                          this.state.selectedApplicant
                                            .correspondence_address.pincode}
                                      </dd>
                                      <dt className="col-sm-4">Country</dt>
                                      <dd className="col-sm-8">
                                        {this.state.selectedApplicant
                                          .correspondence_address &&
                                          this.state.selectedApplicant
                                            .correspondence_address.country}
                                      </dd>
                                    </dl>
                                  </dd>
                                  <dt className="col-sm-3">
                                    Permanent Address:
                                  </dt>
                                  <dd className="col-sm-9 mb-0">
                                    <dl className="row mb-0">
                                      <dt className="col-sm-4">Address</dt>
                                      <dd className="col-sm-8">
                                        {this.state.selectedApplicant
                                          .permanent_address &&
                                          this.state.selectedApplicant
                                            .permanent_address.address}
                                      </dd>
                                      <dt className="col-sm-4">City</dt>
                                      <dd className="col-sm-8">
                                        {this.state.selectedApplicant
                                          .permanent_address &&
                                          this.state.selectedApplicant
                                            .permanent_address.city}
                                      </dd>
                                      <dt className="col-sm-4">State</dt>
                                      <dd className="col-sm-8">
                                        {this.state.selectedApplicant
                                          .permanent_address &&
                                          this.state.selectedApplicant
                                            .permanent_address.state}
                                      </dd>
                                      <dt className="col-sm-4">Pincode</dt>
                                      <dd className="col-sm-8">
                                        {this.state.selectedApplicant
                                          .permanent_address &&
                                          this.state.selectedApplicant
                                            .permanent_address.pincode}
                                      </dd>
                                      <dt className="col-sm-4">Country</dt>
                                      <dd className="col-sm-8">
                                        {this.state.selectedApplicant
                                          .permanent_address &&
                                          this.state.selectedApplicant
                                            .permanent_address.country}
                                      </dd>
                                    </dl>
                                  </dd>
                                </dl>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </ModalBody>
                    </Modal>
                    <CardTitle>Applicants </CardTitle>
                    <CardSubtitle className="mb-3">
                      View and search admission applicants here.
                    </CardSubtitle>
                    {this.props.applicants && (
                      // <MDBDataTableV5
                      //   hover
                      //   entriesOptions={[10, 20, 50]}
                      //   entries={10}
                      //   pagesAmount={4}
                      //   data={data}
                      //   checkbox
                      //   responsive
                      //   autoWidth
                      //   headCheckboxID="id2"
                      //   bodyCheckboxID="checkboxes2"
                      //   getValueCheckBox={(e) => {
                      //     showLogs2(e);
                      //   }}
                      // />
                      <BootstrapTable
                        data={this.props.applicants && this.props.applicants}
                        selectRow={selectRowProp}
                        pagination={true}
                        options={pagination_options}
                        striped
                        search
                        exportCSV
                        ignoreSinglePage
                        responsive
                        hover
                      >
                        <TableHeaderColumn
                          dataField="_id"
                          hidden={true}
                          isKey={true}
                        >
                          ID
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="name"
                          width="70"
                          tdStyle={{ whiteSpace: "normal" }}
                        >
                          Name
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="mobile"
                          width="70"
                          tdStyle={{ whiteSpace: "normal" }}
                        >
                          Mobile
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="email"
                          width="135"
                          tdStyle={{ whiteSpace: "normal" }}
                        >
                          Email
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="father_name"
                          width="70"
                          tdStyle={{ whiteSpace: "normal" }}
                        >
                          Father's Name
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="parent_mobile"
                          width="70"
                          tdStyle={{ whiteSpace: "normal" }}
                        >
                          Father's Mobile
                        </TableHeaderColumn>
                      </BootstrapTable>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { message, loading, applicants } = state.Admission;
  return {
    loading,

    message,
    applicants,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    loadApplicants,
  })(Applicants)
);
