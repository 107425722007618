import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Enquiry Redux states
import {
  SUBMIT_ENQUIRY,
  LOAD_PROGRAMS,
  LOAD_ENQUIRIES,
  UPDATE_ENQUIRY,
  LOAD_APPLICANTS,
} from "./actionTypes";
import {
  submitEnquirySuccessful,
  submitEnquiryFailed,
  updateEnquirySuccessful,
  updateEnquiryFailed,
  loadProgramsSuccessful,
  loadEnquiriesSuccessful,
  loadApplicantsSuccessful,
  apiError,
} from "./actions";

//Include Helper Files with needed methods
import { postSubmitForm } from "../../helpers/forms_helper";

//Submit Enquiry
function* submitEnquiry({ payload: { enquiryDetails } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "enquiries/insert";
    const response = yield call(postSubmitForm, url, enquiryDetails);
    if (response.status === 1) {
      yield* bindEnquiries();
      yield put(submitEnquirySuccessful(response));
    } else {
      yield put(submitEnquiryFailed(response));
    }
  } catch (error) {
    yield put(submitEnquiryFailed(error));
  }
}

//Load Programs
function* loadPrograms({ payload: { programs } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "programs/getall";
    const response = yield call(postSubmitForm, url, programs);
    if (response.status === 1) {
      yield put(loadProgramsSuccessful(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

//Load Enquiries
function* loadEnquiries({ payload: { enquiries } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "enquiries/getall";
    const response = yield call(postSubmitForm, url, enquiries);
    if (response.status === 1) {
      yield put(loadEnquiriesSuccessful(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

//Bind Enquiries
function* bindEnquiries() {
  try {
    let url = process.env.REACT_APP_BASEURL + "enquiries/getall";
    const response = yield call(postSubmitForm, url, "");
    if (response.status === 1) {
      yield put(loadEnquiriesSuccessful(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

//Update Enquiry
function* updateEnquiry({ payload: { enquiryDetails } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "enquiries/update_call_details";
    const response = yield call(postSubmitForm, url, enquiryDetails);
    if (response.status === 1) {
      yield* bindEnquiries();
      yield put(updateEnquirySuccessful(response));
    } else {
      yield put(updateEnquiryFailed(response));
    }
  } catch (error) {
    yield put(updateEnquiryFailed(error));
  }
}

//Load Applicants
function* loadApplicants({ payload: { applicants } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "admissionapplicants/getall";
    const response = yield call(postSubmitForm, url, applicants);
    if (response.status === 1) {
      yield put(loadApplicantsSuccessful(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchSubmitEnquiry() {
  yield takeEvery(SUBMIT_ENQUIRY, submitEnquiry);
}

export function* watchLoadPrograms() {
  yield takeEvery(LOAD_PROGRAMS, loadPrograms);
}

export function* watchLoadEnquiries() {
  yield takeEvery(LOAD_ENQUIRIES, loadEnquiries);
}

export function* watchUpdateEnquiry() {
  yield takeEvery(UPDATE_ENQUIRY, updateEnquiry);
}

export function* watchLoadApplicants() {
  yield takeEvery(LOAD_APPLICANTS, loadApplicants);
}

function* admissionSaga() {
  yield all([
    fork(watchSubmitEnquiry),
    fork(watchLoadPrograms),
    fork(watchLoadEnquiries),
    fork(watchUpdateEnquiry),
    fork(watchLoadApplicants),
  ]);
}

export default admissionSaga;
