import {
  LOAD_DEPARTMENTS,
  LOAD_DEPARTMENTS_SUCCESSFUL,
  LOAD_DESIGNATIONS,
  LOAD_DESIGNATIONS_SUCCESSFUL,
  SUBMIT_EMPLOYEE,
  SUBMIT_EMPLOYEE_SUCCESSFUL,
  API_ERROR,
  SUBMIT_DESIGNATION,
  SUBMIT_DESIGNATION_SUCCESSFUL,
  SUBMIT_DEPARTMENT,
  SUBMIT_DEPARTMENT_SUCCESSFUL,
  SUBMIT_SHIFT,
  SUBMIT_SHIFT_SUCCESSFUL,
  LOAD_SHIFTS,
  LOAD_SHIFTS_SUCCESSFUL,
} from "./actionTypes";

const initialState = {
  message: null,
  loading: false,
};

const administration = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DEPARTMENTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOAD_DEPARTMENTS_SUCCESSFUL:
      state = {
        ...state,
        departments: action.payload.data,
        message: null,
      };
      break;
    case LOAD_DESIGNATIONS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOAD_DESIGNATIONS_SUCCESSFUL:
      state = {
        ...state,
        designations: action.payload.data,
        message: null,
      };
      break;
    case SUBMIT_EMPLOYEE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SUBMIT_EMPLOYEE_SUCCESSFUL:
      state = {
        ...state,
        employee: action.payload.data,
        message: "Employee added successfully.",
        message_type: "Success",
      };
      break;
    case SUBMIT_DESIGNATION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SUBMIT_DESIGNATION_SUCCESSFUL:
      state = {
        ...state,
        designation: action.payload.data,
        message: "Designation added successfully.",
        message_type: "Success",
      };
      break;
    case SUBMIT_DEPARTMENT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SUBMIT_DEPARTMENT_SUCCESSFUL:
      state = {
        ...state,
        designation: action.payload.data,
        message: "Department added successfully.",
        message_type: "Success",
      };
      break;
    case SUBMIT_SHIFT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SUBMIT_SHIFT_SUCCESSFUL:
      state = {
        ...state,
        shift: action.payload.data,
        message: "Shift added successfully.",
        message_type: "Success",
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
        message_type: "Error",
      };
      break;
    case LOAD_SHIFTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOAD_SHIFTS_SUCCESSFUL:
      state = {
        ...state,
        shifts: action.payload.data,
        message: null,
      };
      break;
  }
  return state;
};

export default administration;
