export const LOAD_DEPARTMENTS = "load_departments";
export const LOAD_DEPARTMENTS_SUCCESSFUL = "load_departments_successful";
export const API_ERROR = "api_error";
export const LOAD_DESIGNATIONS = "load_designations";
export const LOAD_DESIGNATIONS_SUCCESSFUL = "load_designations_successful";
export const SUBMIT_EMPLOYEE = "submit_employee";
export const SUBMIT_EMPLOYEE_SUCCESSFUL = "submit_employee_successful";
export const SUBMIT_DESIGNATION = "submit_designation";
export const SUBMIT_DESIGNATION_SUCCESSFUL = "submit_designation_successful";
export const SUBMIT_DEPARTMENT = "submit_department";
export const SUBMIT_DEPARTMENT_SUCCESSFUL = "submit_department_successful";
export const SUBMIT_SHIFT = "submit_shift";
export const SUBMIT_SHIFT_SUCCESSFUL = "submit_shift_successful";
export const LOAD_SHIFTS = "load_shifts";
export const LOAD_SHIFTS_SUCCESSFUL = "load_shifts_successful";
