import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";

//Admission Module
import Admission from "./admission/reducer";

//Administration Module
import Administration from "./administration/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  //Admission Module
  Admission,
  Administration,
});

export default rootReducer;
