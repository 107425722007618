import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

import Enquiries from "../pages/Admission/Enquiries";
import Applicants from "../pages/Admission/Applicants";
import AddEmployees from "../pages/Administration/AddEmployees";
import Designations from "../pages/Administration/Designations";
import Departments from "../pages/Administration/Departments";
import Shifts from "../pages/Administration/Shifts";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/admission/enquiries", component: Enquiries },
  { path: "/admission/applicants", component: Applicants },
  { path: "/administration/addemployees", component: AddEmployees },
  { path: "/administration/designations", component: Designations },
  { path: "/administration/departments", component: Departments },
  { path: "/administration/shifts", component: Shifts },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
