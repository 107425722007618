import React, { Component, useState } from "react";
import makeAnimated from "react-select/animated";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

// action
import {
  loadDepartments,
  loadDesignations,
  submitDepartment,
} from "../../store/actions";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
const animatedComponents = makeAnimated();

class Departments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      designations: [],
      selected_designation: [],
    };
    // handleEnquirySubmit
    this.handleDepartmentSubmit = this.handleDepartmentSubmit.bind(this);
    this.showNotification.bind(this);
  }
  componentDidMount() {
    this.props.loadDepartments("");
    this.props.loadDesignations("");
  }

  showNotification(message, type) {
    var title = type;

    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 5000,
      extendedTimeOut: 1000,
      closeButton: true,
      progressBar: true,
      preventDuplicates: false,
      newestOnTop: true,
      showEasing: "linear",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
    };

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (type === "Success") toastr.success(message, title);
    else toastr.error(message, title);
  }
  handleDesignationChange = (selected, event) => {
    let selected_designation = [];
    selected.forEach((designation) =>
      selected_designation.push({
        name: designation.name,
      })
    );
    this.setState({
      designations: selected,
      selected_designation,
    });
  };
  // handleEmployeeSubmit
  handleDepartmentSubmit(event, values) {
    values = { ...values, designations: this.state.selected_designation };
    //console.log(values);
    this.props.submitDepartment(values);
    this.form.reset();
  }

  render() {
    if (this.props.message) {
      this.showNotification(this.props.message, this.props.message_type);
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Dashboard" breadcrumbItem="Departments" />
            <Row>
              <Col xl="6">
                <Card>
                  <CardBody>
                    <CardTitle>Add Departments </CardTitle>
                    <CardSubtitle className="mb-3">
                      View and add new departments here.
                    </CardSubtitle>
                    <AvForm
                      id="designations_form"
                      ref={(form) => (this.form = form)}
                      className="needs-validation"
                      onValidSubmit={this.handleDepartmentSubmit}
                    >
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <Label htmlFor="validationCustom01">
                              Name of Department
                            </Label>
                            <AvField
                              name="name"
                              placeholder="Enter Department Name"
                              type="text"
                              errorMessage="Enter Department Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label htmlFor="validationCustom01">Faculty</Label>
                            <AvField
                              name="faculty"
                              placeholder="Enter Faculty Name"
                              type="text"
                              errorMessage="Enter Faculty Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                          <FormGroup className="mb-0 templating-select select2-container">
                            <Label>Designations</Label>
                            <Select
                              value={this.state.designations}
                              isMulti={true}
                              name="designations"
                              onChange={this.handleDesignationChange}
                              options={this.props.designations}
                              //getOptionValue={(option) => option["label"]}
                              classNamePrefix="select2-selection"
                              components={animatedComponents}
                              required
                              closeMenuOnSelect={false}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Button color="primary" type="submit">
                        Submit
                      </Button>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle>Existing Departments </CardTitle>
                    <BootstrapTable
                      data={this.props.departments && this.props.departments}
                      striped
                      search
                      exportCSV
                      responsive
                      hover
                    >
                      <TableHeaderColumn
                        autoValue={true}
                        dataField="_id"
                        isKey={true}
                        hidden={true}
                      >
                        Id
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="name"
                        width="250"
                        tdStyle={{ whiteSpace: "normal" }}
                      >
                        Department Name
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="faculty"
                        width="200"
                        tdStyle={{ whiteSpace: "normal" }}
                      >
                        Faculty
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    message,
    message_type,
    loading,
    departments,
    designations,
  } = state.Administration;
  return {
    loading,
    departments,
    designations,
    message,
    message_type,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    loadDepartments,
    loadDesignations,
    submitDepartment,
  })(Departments)
);
